<template>

  <img height="90px" src="https://pix.milkywan.fr/la0Ke68n.jpg">
  <h1 >La plateforme myUSL est en cours de développement.</h1>
  <a   href="https://login.microsoftonline.com/?whr=usl-syndicat.org">Acces à l'intranet Microsoft Office</a>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
